import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import WhatsappChat from "../global/botonWhatsapp/BotonWhatsapp"
import { FaCheck, FaCreditCard, FaMoneyBillWave } from 'react-icons/fa'
import SocialMedia from "../global/SocialMedia";
import { FiPhoneCall, FiMapPin, FiClock, FiCalendar } from 'react-icons/fi'
import { BiChevronRightCircle } from 'react-icons/bi'
import { GoCalendar } from 'react-icons/go'

const menu = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'About',
    link: '/about',
  },
  {
    name: 'Services',
    link: '/services',
  },
  {
    name: 'Gallery',
    link: '/gallery',
  },
  {
    name: 'Contact',
    link: '/contact',
  },
]

function Footer() {
  const { rpdata } = useContext(GlobalDataContext);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>

      <div className="block w-full border-[2px] border-black bg-black">
        <div className=" block md:flex w-[80%]  mx-auto justify-center items-center text-center my-2">
          <div className="w-[100%] md:w-[30%] h-full flex mx-auto justify-center items-center md:my-0 my-5 text-white">
          <FiPhoneCall style={{ fontSize: "45px" }} className=" text-white border-white border-[2px] p-1 mr-3 rounded-md" />
            <>
                <h5>
                    <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}>
                      {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                    </a>
                </h5>
            </>
          </div>
          <div className="w-[100%] md:w-[40%] h-full flex mx-auto justify-center items-center lg:-mt-10 ">
            <img src={rpdata?.dbPrincipal?.logo} className="w-[80%] md:w-[65%] rounded-lg bg-white p-1" alt="Not Found" />
          </div>
          <div className="w-[100%] md:w-[30%] h-full flex mx-auto justify-center items-center md:my-0 my-5 text-white">
            {/* <h5 className='text-black'>{rpdata?.dbPrincipal?.location[0]?.address}</h5> */}
            {
              rpdata?.dbPrincipal?.phones.length > 1 ?
                <>
                  <FiPhoneCall style={{ fontSize: "45px" }} className=" text-white border-white border-[2px] p-1 mr-3 rounded-md" />

                  <h5>
                    <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[1]?.phone}`}>
                      {rpdata?.dbPrincipal?.phones?.[1]?.phone}
                    </a>
                  </h5>
                </>
                :

                <>
                <FiMapPin style={{ fontSize: "45px" }} className=" text-white border-white border-[2px] p-1 mr-3 rounded-md" />

                <h5>
                  {rpdata?.dbPrincipal?.location?.[0].address}
                </h5>
                </>
            }
          </div>
        </div>
        <div className=" w-[80%] mx-auto block md:flex gap-x-2 my-3">
          <div className="text-white w-[100%] md:w-[30%] h-[100%] p-4">
            <h5>About</h5>
            <p className="pb-4">{rpdata?.dbAbout?.[1].text.substring(0, 182)}...</p>
          </div>
          <div className="text-white w-[100%] md:w-[25%] h-[100%] p-4">
            <h5>Services</h5>
            <ul className="list-inner-section text-start">
              {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                return (
                  <Link to="/services" key={index}>
                    <li key={index} className="py-1 px-2 flex items-center">
                      <BiChevronRightCircle className="h-[20px] w-[20px] text-center" />
                      <span className="px-2 text-center">{item.name}</span>
                    </li>
                  </Link>
                )
              })}
            </ul>
          </div>
          <div className="text-white w-[100%] md:w-[15%] h-[100%] p-4">
            <h5>Navigation</h5>
            <ul className="pl-2">
              {
                menu.map((item, index) => {
                  return (
                    <li className="py-2" onClick={goToTop} key={index}>
                      <Link to={item.link} className="flex">
                        <FaCheck className="self-center" />
                        <span className="pl-2">{item.name}</span>
                      </Link>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="text-white  w-[100%] md:w-[30%] h-[100%] p-4">
            <h5>Contact</h5>
            <ul className="pl-2">
              {rpdata?.dbPrincipal?.location.slice(0,1).map((item, index) => {
                return (
                  <Link to="/contact" key={index}>
                    <li className="py-2 flex items-center">
                      <FiMapPin fontSize={25} />
                      <span className="pl-3">{item.address}</span>
                    </li>
                  </Link>
                );
              })}

              {rpdata?.dbPrincipal?.workdays.map((item, index) => {
                return (
                  
                  <li className="py-2 flex items-center" key={index} >
                    <GoCalendar fontSize={30} />
                    <span className="pl-3">{item.day}</span>
                  </li>
                );
              })}
              {rpdata?.dbPrincipal?.workHours.map((item, index) => {
                return (
                  <li className="py-2 flex items-center" key={index}>
                    <FiClock fontSize={30} />
                    <span className="pl-3">{item.hour}</span>
                  </li>
                );
              })}
              {
                rpdata?.dbPrincipal?.paymentMethod === 'we accept cash & checks' ?
                  <li className="py-2 flex items-center">
                    <FaMoneyBillWave fontSize={30} />
                    <span className="pl-3 capitalize">{rpdata?.dbPrincipal?.paymentMethod}</span>
                  </li>
                  :
                  <li className="py-2 flex flex-col">
                    <span className="flex items-center">
                      <FaCreditCard fontSize={25} />
                      <span className="pl-3 capitalize">{rpdata?.dbPrincipal?.paymentMethod}</span>
                    </span>
                  </li>
              }
              
            </ul>
          </div>
        </div>
      </div>
      <WhatsappChat />
      <div className="w-full flex justify-around md:flex-row items-center flex-col-reverse text-center text-white  py-4 bg-footer">
        <p>
          ©2022, Copy Right By {rpdata?.dbPrincipal?.name}. All Rights Reserved
        </p>
        <ul className="flex">
          {rpdata?.dbSocialMedia?.redes.map((item, index) => {
            return (
              <li key={index} className='mr-7'>
                <a href={item.url} target="_blank" rel='noopener noreferrer' >
                  <i
                    className={`fab fa-${item.icon}`}
                    aria-hidden="true"
                  />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default Footer;
